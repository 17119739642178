<template>
  <div class="segment-cards">
    <div class="segment-cards-table-components">
      <discount-cards-table
        :items="items"
        :total="total"
        :is_loading="isLoading"
        :presentation_table="true"
      />
    </div>
    <pagination
      :total="total"
      table_name="segment_cards"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Pagination from '@/views/shared/components/table/Pagination'
  import DiscountCardsTable from '../components/DiscountCardsTable.vue'
  export default {
    name: 'SegmentCards',

    components: {
      DiscountCardsTable,
      Pagination
    },

    apollo: {
      ReadSegmentCards: {
        query: require('../gql/ReadSegmentCards.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              id: this.$route.params.id,
              type: this.$route.params.type,
              pagination: {
                skip: this.pagination.skip,
                take: this.pagination.take
              }
            }
          }
        },
        result({ data }) {
          this.items = data?.ReadSegmentCards?.list ?? []
          this.total = data?.ReadSegmentCards.total ?? null
          this.isLoading = false
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        items: [],
        total: null,
        isLoading: true,
        pagination: {
          skip: 0,
          take: 20
        }
      }
    },

    mounted() {
      this.setBreadcrumbs({
        title: 'Карты сегмента',
        is_go_back: true,
        discount: { value: this.$route.params.discount }
      })
    },

    beforeDestroy() {
      this.setBreadcrumbs({})
    },

    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),

      handlerPagination(option) {
        this.pagination.skip = (option.current_page - 1) * option.take
        this.pagination.take = option.take
      }
    }
  }
</script>

<style scoped lang="scss">
  .segment-cards {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .segment-cards-table-components {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
</style>
