var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "segment-cards"
  }, [_c('div', {
    staticClass: "segment-cards-table-components"
  }, [_c('discount-cards-table', {
    attrs: {
      "items": _vm.items,
      "total": _vm.total,
      "is_loading": _vm.isLoading,
      "presentation_table": true
    }
  })], 1), _c('pagination', {
    attrs: {
      "total": _vm.total,
      "table_name": "segment_cards"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }